import React, { useState } from "react";
import { Button } from "./Button";
import {
  Box,
  Container,
  Grid,
  Paper,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Switch,
  Typography,
} from "@mui/material";
import { IconContext } from "react-icons/lib";
import { Link } from "react-router-dom";
import { FaFire } from "react-icons/fa";
import { BsXDiamondFill } from "react-icons/bs";
import { GiCrystalize } from "react-icons/gi";
import "./Pricing.css";
import cardPayment from "../../assets/img/cardPayment.svg";

function Pricing() {
  const [university, setUniversity] = useState("Imperial College London");
  const [isAnnual, setIsAnnual] = useState(false);

  const universities = [
    { label: "Imperial (Medicine)", value: "Imperial College London" },
      { label: "Manchester (Dentistry)", value: "University of Manchester" },
      { label: "Sheffield (Dentistry)", value: "University of Sheffield" }
  ];
  return (
    <Container className="pricingContainer">
      <h1 className="pricing__heading1">Pricing</h1>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="100%"
      >
        <FormControl
          className="select-university"
          fullWidth
          margin="normal"
          variant="filled"
          style={{ maxWidth: "600px", marginTop: "40px" }} // Ensures maximum width
        >
          <InputLabel id="university-select-label">
            Select University
          </InputLabel>
          <Select
            labelId="university-select-label"
            value={university}
            onChange={(e) => setUniversity(e.target.value)}
            label="University"
          >
            {universities.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        marginY={2}
      >
        <div style={{ marginRight: "8px" }}>
          <Switch
            checked={isAnnual}
            onChange={() => setIsAnnual(!isAnnual)}
            name="pricingToggle"
            color="primary"
            mr={2}
          />
        </div>
      </Box>

      <PricingSection university={university} isAnnual={isAnnual} />
    </Container>
  );
}

function PricingSection({ university, isAnnual }) {
  const features = {
    "Imperial College London": {
      monthly: [
        {
          title: "Complete Access",
          price: "£2.99",
          details: ["700+ Questions", "300+ VSAQs", "Comprehensive SAQs"],
        },
      ],
      annual: [
        {
          title: "Complete Access",
          price: "£19.99", // 10% discount for annual
          details: ["700+ Questions", "300+ VSAQs", "Comprehensive SAQs"],
        },
      ],
    },
    "University of Manchester": {
      monthly: [
        {
          title: "Complete Access",
          price: "£2.99",
          details: ["400+ MCQs", "Syllabus Specific", "Interactive Learning "],
        },
      ],
      annual: [
        {
          title: "Complete Access",
          price: "£19.99", // 10% discount for annual
          details: ["400+ MCQs", "Syllabus Specific", "Interactive Learning "],
        },
      ],
      },
      "University of Sheffield": {
          monthly: [
              {
                  title: "Complete Access",
                  price: "£2.99",
                  details: ["290+ MCQs", "Syllabus Specific", "Interactive Learning"],
              },
          ],
          annual: [
              {
                  title: "Complete Access",
                  price: "£19.99", // 10% discount for annual
                  details: ["290+ MCQs", "Syllabus Specific", "Interactive Learning"],
              },
          ],
      },
  };

  const selectedFeatures =
    features[university][isAnnual ? "annual" : "monthly"];
  return (
    <IconContext.Provider value={{ color: "#fff", size: 64 }}>
      <div className="pricing__section">
        <div className="pricing__wrapper">
          <div className="pricing__container">
            <h1 className="pricing__heading">
              {isAnnual ? "Annual" : "Monthly"}
            </h1>
            {selectedFeatures.map((plan, index) => (
              <Link
                to="/register"
                key={index}
                className="pricing__container-card"
              >
                <div className="pricing__container-cardInfo">
                  <div className="icon">
                    <img
                      src={cardPayment}
                      alt="card payment"
                      style={{ width: 100, height: 100 }}
                    />
                  </div>
                  <h3>{plan.title}</h3>
                  <h4>{plan.price}</h4>
                  <p style={{ fontStyle: "italic" }}>
                    {isAnnual ? "per year" : "per month"}
                  </p>
                  <ul className="pricing__container-features">
                    {plan.details.map((detail, idx) => (
                      <li key={idx}>{detail}</li>
                    ))}
                  </ul>
                  <Button buttonSize="btn--wide">Choose Plan</Button>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </IconContext.Provider>
  );
}

export default Pricing;
