import axios from "axios";
axios.defaults.withCredentials = true;
const baseURL = 'https://preclinique.co.uk/api';

//const baseURL = 'http://localhost:8000/api';

//NODE_ENV = 'development'
//NODE_ENV = 'production'


export async function onRegistration(registrationData) {
    return await axios.post(
        `${baseURL}/register`,
        registrationData
    );
}

export async function getSubId(userId) {
    try {
        const response = await axios.get(`${baseURL}/get-subscription-id/${userId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
}


export async function stripeCheckoutMonthly(userId) {
    return await axios.post(
        `${baseURL}/create-stripe-checkout-session-for-monthly`,
        { userId: userId }
    );
}


export async function stripeCheckoutYearly(userId) {
    return await axios.post(
        `${baseURL}/create-stripe-checkout-session-for-yearly`,
        { userId: userId }
    );
}



export async function getSubscriptionStatus() {
    try {
        const response = await axios.get(`${baseURL}/subscription/status`);
        return response.data;
    } catch (error) {
        throw error;
    }
}


export async function cancelSubscription(subscriptionId) {
    try {
        const response = await axios.post(`${baseURL}/cancel-subscription/${subscriptionId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
}




export async function onLogin(loginData) {
    return await axios.post(`${baseURL}/login`, loginData);
}

export async function onLogout() {
    return await axios.get(`${baseURL}/logout`);
}

export async function fetchProtectedInfo() {
    return await axios.get(`${baseURL}/protected`);
}

export async function getUserDetails() {
    return await axios.get(`${baseURL}/getUserDetails`);
}

export async function updateUserDetails(updateData) {
    return await axios.put(
        `${baseURL}/updateUserDetails`,
        updateData
    );
}

export async function initiatePasswordReset(emailData) {
    return await axios.post(
        `${baseURL}/reset-password/initiate`,
        emailData
    );
}

export async function resetPassword(passwordData) {
    return await axios.post(
        `${baseURL}/reset-password/insert`,
        passwordData
    );
}

export async function updatePassword(passwordData) {
    return await axios.put(
        `${baseURL}/updatePassword`,
        passwordData
    );
}

export async function confirmEmail(confirmation_token) {
    return await axios.get(
        `${baseURL}/confirm-account?confirmation_token=${confirmation_token}`
    );
}

export async function getAllMainCategoriesWithQuestionCount(
    universityName,
    courseName
) {
    return await axios.get(
        `${baseURL}/question-bank/categories?university_name=${encodeURIComponent(
            universityName
        )}&course_name=${encodeURIComponent(courseName)}`
    );
}

export async function getSubcategoriesByMainCategoryWithQuestionCount(
    categoryId,
    userId
) {
    return await axios.get(
        `${baseURL}/question-bank/categories/${categoryId}/subcategories?userId=${userId}`
    );
}

export async function getQuestionsBySubcategories(
    subcategoryIds,
    //userId,
    type = 1,
    excludedQuestionIds = []
) {
    console.log("Received parameters:");
    console.log("subcategoryIds:", subcategoryIds);
    //console.log("userId:", userId);
    console.log("type:", type);
    console.log("excludedQuestionIds:", excludedQuestionIds);

    const queryParams = new URLSearchParams({
        subcategories: subcategoryIds.join(","), // Convert array to comma-separated string
        //userId: userId,
        type: type,
        excludedQuestionIds: excludedQuestionIds.join(","), // Convert array to comma-separated string
    });

    const url = `${baseURL}/question-bank/questions?${queryParams}`;

    console.log("Constructed URL:", url);

    return await axios.get(url);
}

export async function getQuestionsToExclude(userId) {
    return await axios.get(
        `${baseURL}/question-bank/getQuestionsToExclude?user_id=${userId}`
    );
}

export async function getQuestionsByIds(questionIds, userId) {
    const questionsQuery = questionIds.join(",");
    return await axios.get(
        `${baseURL}/question-bank/getQuestionsByIds?questions=${questionsQuery}&userId=${userId}`
    );
}

//user progress stuff
export async function saveUserProgress(userId, subcatIds, lastQuestionIds) {
    const data = {
        userId,
        subcatIds,
        lastQuestionIds,
    };

    return await axios.post(
        `${baseURL}/userProgress/saveProgress`,
        data
    );
}

export async function getUserProgress(userId) {
    return await axios.get(`${baseURL}/userProgress/${userId}`);
}

export async function updateTimestamp(userId) {
    return await axios.post(
        `${baseURL}/userProgress/updateTimestamp/${userId}`
    );
}

//farqaans endpoints
export async function completeQuestion(userId, questionId, completed, result) {
    const data = {
        user_id: userId, // Make sure userId is not null
        question_id: questionId, // Make sure questionId is not null
        completed: completed,
        result: result,
    };
    console.log("Data to be sent:", data);

    return await axios.post(
        `${baseURL}/question-bank/completeQuestion`,
        data,
        {
            headers: {
                "Content-Type": "application/json", // Set the content type to JSON
            },
        }
    );
}

export async function resetAllProgress(userId) {
    return await axios.post(
        `${baseURL}/question-bank/resetAllProgress/${userId}`
    );
}

export async function flagQuestion(userId, questionId, flagged, timestamp) {
    const data = {
        user_id: userId, // Make sure userId is not null
        question_id: questionId,
        timestamp,
    };

    return await axios.post(
        `${baseURL}/question-bank/flagQuestion`,
        data
    );
}

export async function unflagQuestion(userId, questionId) {
    const data = {
        user_id: userId, // Make sure userId is not null
        question_id: questionId,
    };

    return await axios.post(
        `${baseURL}/question-bank/unflagQuestion`,
        data
    );
}

export async function reviewFlaggedQuestions(userId) {
    return await axios.get(
        `${baseURL}/question-bank/reviewFlaggedQuestions/${userId}`
    );
}

export async function getAllScoresForPercentile() {
    return await axios.get(
        `${baseURL}/question-bank/getAllScoresForPercentile`
    );
}

export async function getAllUserScores() {
    return await axios.get(
        `${baseURL}/question-bank/getAllUserScores`
    );
}

export async function getUserAverageScore(userId) {
    return await axios.get(
        `${baseURL}/question-bank/getUserAverageScore?user_id=${userId}`
    );
}
export async function getScoresFromCourse(course, universityName) {
    const queryParams = universityName
        ? `?course=${course}&university_name=${universityName}`
        : `?course=${course}`;

    return await axios.get(
        `${baseURL}/question-bank/getScoresFromCourse${queryParams}`
    );
}

export async function getScoresFromYear(class_year) {
    return await axios.get(
        `${baseURL}/question-bank/getScoresFromYear?class_year=${class_year}`
    );
}

export async function removeFromReview(userId, questionId) {
    const data = {
        user_id: userId, // Make sure userId is not null
        question_id: questionId,
    };

    return await axios.post(
        `${baseURL}/question-bank/removeFromReview`,
        data
    );
}

export async function mockTestQuestions() {
    return await axios.get(
        `${baseURL}/question-bank/mockTestQuestions`
    );
}

export async function retrieveFromReview(userId, catId = "", subcatId = "") {
    return await axios.get(
        `${baseURL}/question-bank/retrieveFromReview?user_id=${userId}&cat_id=${catId}&subcat_id=${subcatId}`
    );
}

export async function submitTest(
    userId,
    universityName,
    classYear,
    score,
    courseName
) {
    const data = {
        user_id: userId,
        university_name: universityName,
        class_year: classYear,
        score: score,
        course: courseName,
    };

    return await axios.post(
        `${baseURL}/question-bank/submitTest`,
        data
    );
}

export async function insertForReview(
    userId,
    questionId,
    questionText, // Add question_text parameter
    userOptionText,
    correctOptionText,
    correct
) {
    const data = {
        user_id: userId,
        question_id: questionId,
        question_text: questionText, // Include question_text in data
        user_option_text: userOptionText,
        correct_option_text: correctOptionText,
        correct,
    };
    try {
        const response = await axios.post(
            `${baseURL}/question-bank/insertForReview`,
            data
        );
        return response;
    } catch (error) {
        console.error(
            "Error in Axios call:",
            error.response ? error.response.data : error
        );
        throw error; // Re-throw the error so it can be caught where the function is called
    }
}

export async function howManyQuestionsAreThereTotal(course, university_name) {
    const queryParams = university_name
        ? `?course=${course}&university_name=${university_name}`
        : `?course=${course}`;

    return await axios.get(
        `${baseURL}/question-bank/howManyQuestionsAreThereTotal${queryParams}`
    );
}

export async function howManyQuestionsCorrect(user_id) {
    return await axios.get(
        `${baseURL}/question-bank/howManyQuestionsCorrect?user_id=${user_id}`
    );
}

export async function howManyQuestionsIncorrect(user_id) {
    return await axios.get(
        `${baseURL}/question-bank/howManyQuestionsIncorrect?user_id=${user_id}`
    );
}

export async function howManyQuestionsAttempted(user_id) {
    return await axios.get(
        `${baseURL}/question-bank/howManyQuestionsAttempted?user_id=${user_id}`
    );
}

export async function resumeSession(userId) {
    return await axios.get(
        `${baseURL}/question-bank/resumeSession?user_id=${userId}`
    );
}

export async function saveSession(data) {
    console.log("datatobesent", data);
    return await axios.post(
        `${baseURL}/question-bank/saveSession`,
        data,
        {
            headers: {
                "Content-Type": "application/json", // Set the content type to JSON
            },
        }
    );
}

export async function deleteSession(userId) {
    return await axios.delete(
        `${baseURL}/question-bank/deleteSession?user_id=${userId}`
    );
}


export async function reportQuestionInsert(
    questionId,
    problem,
    university,
    course,
    //category,
    //subcategory,
    date
) {
    const data = {
        questionId,  // Match key names
        problem,
        university,
        course,
      //  category,
        //subcategory,
        date,
    };

    try {
        const response = await axios.post(`${baseURL}/question-bank/reportQuestionInsert`, data);
        return response;
    } catch (error) {
        console.error(
            "Error in Axios call:",
            error.response ? error.response.data : error
        );
        throw error;
    }
}


export async function getQuestionImageLink(questionId) {
    return await axios.get(
        `${baseURL}/question-bank/getQuestionImageLink?question_id=${questionId}`
    );
}
//commen


//comments

// In your frontend axios file (auth.js or similar)
export async function getCommentsByQuestionId(questionId, userId) {
    return await axios.get(
        `${baseURL}/question-bank/questions/${questionId}/comments`,
        {
            params: { userId },
        }
    );
}

export async function addComment(commentData) {
    const { question_id, user_id, comment_text, parent_comment_id } = commentData;
    // Update the URL to match the correct base path
    return await axios.post(
        `${baseURL}/question-bank/questions/${question_id}/comments`,
        {
            user_id,
            comment_text,
            parent_comment_id,
        }
    );
}

export async function likeComment(commentData) {
    const { user_id, comment_id, is_like } = commentData;
    return await axios.post(`${baseURL}/question-bank/comments/like`, {
        user_id,
        comment_id,
        is_like, // true for like, false for dislike
    });
}


    //furqan portal endpoints start here